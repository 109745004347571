import { useAxiosGet,useAxiosPost } from "./api.js";


export const useProject = async () =>{
    
    const store = useProjectStore()
    const errorStore = useErrorStore()

    const get = async () =>{
        try {
            const {data:projects,message} = await useAxiosGet('project')
            store.setProjects(projects)
            return projects
        } catch (error) {
            console.log("Project Error : ",error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const getSingle = async (id) =>{
        try {
            const {data:project,message} = await useAxiosGet(`project/${id}`)
            return project
        } catch (error) {
            console.log("Project Error : ",error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const create = async (data) =>{
        try {
            const {data:project,message} = await useAxiosPost(`project`,data)
            store.addProject(project)
            errorStore.showError(message,true,'success')
            return 
        } catch (error) {
            console.log("Project Error : ",error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const update = async (id,data) =>{
        try {
            const {data:project,message} = await useAxiosPatch(`project/${id}`,data)
            store.updateProject(id,project)
            errorStore.showError(message,true,'success')
            return project
        } catch (error) {
            console.log("Project Error : ",error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const remove = async (id) =>{
        try {
            const {data:project,message} = await useAxiosPost(`project/${id}/archive`,{})
            store.deleteProject(id)
            errorStore.showError(message,true,'success')
            return project
        } catch (error) {
            console.log("Project Error : ",error)
            errorStore.showError(error.message,true,'error')
        }
    }

    // Associates

    const getAssignedAssociates = async () => {
        try {
            const {data:assignedAssociates,message} = await useAxiosGet('user/assigned-associates')
            store.setAssignedAssociates(assignedAssociates)
        } catch (error) {
            console.log(error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const getAllAssociates = async () => {
        try {
            const {data:allAssociates,message} = await useAxiosGet('user/associates')
            store.setAllAssociates(allAssociates)
        } catch (error) {
            console.log(error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const getProjectAssociates = async(id) => {
       try {
         const {data:associates,message} = await useAxiosGet(`project/${id}/associates`)
         return associates
       } catch (error) {
        console.log(error)
        errorStore.showError(error.message,true,'error')
       }
    }

    const assignProject = async(id,ssmProjectId,va_id,employee_id) => {
        try {
            const {data:associates,message} = await useAxiosPost(`project/${id}/assign`,{
                va_id:va_id,
                employee_id:[Number(employee_id)],
                ssm_project_id:ssmProjectId
            })
            errorStore.showError(message,true,'success')
            return associates
        } catch (error) {
            console.log(error)
            errorStore.showError(error.message,true,'error')
        }

    }
        
    const revokeProject = async(id,ssmProjectId,va_id,employee_id) => {
        try {
            const {data:associates,message} = await useAxiosPost(`project/${id}/revoke`,{
                va_id:va_id,
                employee_id:[Number(employee_id)],
                ssm_project_id:ssmProjectId
            })
            errorStore.showError(message,true,'success')
            return associates
        } catch (error) {
            console.log(error)
            errorStore.showError(error.message,true,'error')
        }
    }
    
    return {
        projects: store.getProjects,
        get,
        getSingle,
        create,
        update,
        remove,
        getProjectAssociates,
        assignProject,
        revokeProject,
        getAssignedAssociates,
        getAllAssociates
    }
}